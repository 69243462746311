import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ScienceIcon from "@mui/icons-material/Science";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { navigateToUrl } from "single-spa";
import { GetAlertwestTheme } from "@digitalpath-inc/alertwest-components-react/theme";

const theme = GetAlertwestTheme({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  cursor: "pointer",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  maxWidth: 220,
  margin: "0 auto",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
  },
}));

const tiles = [
  // {
  //   title: 'AI Metrics',
  //   icon: QueryStatsIcon,
  //   path: '/ai-utils/metrics',
  //   color: theme.palette.info.main // Blue for analytics/metrics
  // },
  // {
  //   title: 'MLFlow',
  //   icon: ScienceIcon,
  //   path: '/ai-utils/mlflow',
  //   color: theme.palette.secondary.main // Purple for ML/scientific tools
  // },
  {
    title: "Propagation",
    icon: LocalFireDepartmentIcon,
    path: "/propagation",
    color: theme.palette.warning.main, // Orange for signal/network related
    description: "View and run fire propagation simulations",
  },
  // {
  //   title: "Alerts",
  //   icon: NotificationsActiveIcon,
  //   path: "/alerts",
  //   color: theme.palette.error.main, // Red for alerts/notifications
  // },
];

function HomePage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} justifyContent="center">
        {tiles.map((tile) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={tile.title}>
            <StyledPaper elevation={3} onClick={() => navigateToUrl(tile.path)}>
              <IconButton
                sx={{
                  backgroundColor: tile.color,
                  color: theme.palette.common.white,
                  width: 56,
                  height: 56,
                  mb: 1.5,
                  "&:hover": {
                    backgroundColor: tile.color,
                    transform: "scale(1.1)",
                  },
                }}
                size="large"
              >
                <tile.icon fontSize="medium" />
              </IconButton>
              <Typography
                variant="h6"
                component="h2"
                sx={{ mb: 0.5, fontSize: "1.1rem" }}
              >
                {tile.title}
              </Typography>
              {tile.description && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.85rem" }}
                >
                  {tile.description}
                </Typography>
              )}
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default function Root(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          minHeight: "100vh",
          py: 4,
          px: 2,
        }}
      >
        <HomePage />
      </Box>
    </ThemeProvider>
  );
}
